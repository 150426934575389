import { render, staticRenderFns } from "./tripartite.vue?vue&type=template&id=1c846c63&scoped=true&"
import script from "./tripartite.vue?vue&type=script&lang=ts&"
export * from "./tripartite.vue?vue&type=script&lang=ts&"
import style0 from "./tripartite.vue?vue&type=style&index=0&id=1c846c63&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c846c63",
  null
  
)

export default component.exports