












































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Tripartite'
})
export default class Tripartite extends Vue {}
